.virtual-machines .design-component-instance-node-2 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.virtual-machines .content-container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 16px;
  padding: 0px 0px 20px;
  position: relative;
}

.no-gpu-selected {
  opacity: 0.8;
}

.virtual-machines .design-component-instance-node-3 {
  align-self: stretch !important;
  background-color: transparent !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.virtual-machines .promo-section {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding: 10px 0px;
  position: relative;
  width: 100%;
}

.virtual-machines .promo-container {
  align-items: center;
  align-self: stretch;
  background: linear-gradient(60deg, #F094FA 13%, #F5576E 87%);
  border-radius: 30px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 24px;
  position: relative;
  width: 100%;
  text-align: left;
}

.virtual-machines .promo-content {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.virtual-machines .input-label-8 {
  align-self: stretch;
  color: #020627;
  font-family: var(--h3-font-family);
  font-size: var(--h3-font-size);
  font-style: var(--h3-font-style);
  font-weight: var(--h3-font-weight);
  letter-spacing: var(--h3-letter-spacing);
  line-height: var(--h3-line-height);
  margin-top: -1px;
  position: relative;
}

.virtual-machines .p {
  align-self: stretch;
  color: #020627;
  font-family: "Jost", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.virtual-machines .text-wrapper-6 {
  color: #020627;
  font-family: var(--font-semibold-font-family);
  font-size: var(--font-semibold-font-size);
  font-style: var(--font-semibold-font-style);
  font-weight: var(--font-semibold-font-weight);
  letter-spacing: var(--font-semibold-letter-spacing);
  line-height: var(--font-semibold-line-height);
}

.virtual-machines .text-wrapper-7 {
  text-decoration: underline;
  color: #020627;
}

.virtual-machines .os-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  padding: 20px 0px;
  position: relative;
  width: 100%;
}

.virtual-machines .os-caption {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 16px 24px;
  position: relative;
  width: 242px;
}

.virtual-machines .input-label-9 {
  font-family: 'Jost';
  color: var(--link-color);
  font-size: var(--header-2-font-size);
  font-style: var(--header-2-font-style);
  font-weight: var(--header-2-font-weight);
  letter-spacing: var(--header-2-letter-spacing);
  line-height: var(--header-2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.virtual-machines .os-options {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 8px;
  position: relative;
}

.virtual-machines .design-component-instance-node-4 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}

.virtual-machines .server-selection-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 24px;
  position: relative;
  width: 100%;
}

.virtual-machines .div-4 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
  padding: 24px;
  position: relative;
}

.virtual-machines .inputlabel {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--h1-font-family);
  font-size: var(--h1-font-size);
  font-style: var(--h1-font-style);
  font-weight: var(--h1-font-weight);
  letter-spacing: var(--h1-letter-spacing);
  line-height: var(--h1-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.virtual-machines .p-inputlabel {
  color: var(--variable-collection-font-color);
  font-family: var(--paragraph-font-family);
  font-size: var(--paragraph-font-size);
  font-style: var(--paragraph-font-style);
  font-weight: var(--paragraph-font-weight);
  letter-spacing: var(--paragraph-letter-spacing);
  line-height: var(--paragraph-line-height);
  position: relative;
  width: fit-content;
}

.virtual-machines .server-types {
  &-container {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: center;
  max-width: 800px;
  padding: 0px 24px;
  position: relative;
  width: 100%;
}

.virtual-machines .server-filters {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  justify-content: flex-end;
  padding: 8px 4px;
  position: relative;
  width: 100%;
}

.virtual-machines .filters-label {
  color: var(--variable-collection-font-color);
  font-family: var(--font-bold-font-family);
  font-size: var(--font-bold-font-size);
  font-style: var(--font-bold-font-style);
  font-weight: var(--font-bold-font-weight);
  letter-spacing: var(--font-bold-letter-spacing);
  line-height: var(--font-bold-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.virtual-machines .filter-options {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.virtual-machines .filter {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.virtual-machines .design-component-instance-node-5 {
  flex: 0 0 auto !important;
}

.virtual-machines .input-label-10 {
  color: #ddd6ed;
  font-family: "Jost", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.virtual-machines .caption {
  color: var(--variable-collection-font-color);
  font-family: var(--h3-font-family);
  font-size: var(--h3-font-size);
  font-style: var(--h3-font-style);
  font-weight: var(--h3-font-weight);
  letter-spacing: var(--h3-letter-spacing);
  line-height: var(--h3-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}


.virtual-machines .div-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: center;
  padding: 0px 0px;
  position: relative;
  width: 100%;
}

.virtual-machines .order-summary {
  align-items: flex-start;
  align-self: stretch;
  background-color: #13173f80;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  padding: 24px 16px;
  position: relative;
}

.virtual-machines .order-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.react-tooltip__show {
  z-index: 999999;
}
