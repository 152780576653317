.button-default-pill {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 1px;
  justify-content: center;
  padding: 1px;
  position: relative;
  width: 196.8px;
}

.button-default-pill .button-pill {
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: center;
  padding: 4px 8px;
  position: relative;
  width: 100%;
}

.button-default-pill .input-label-5 {
  color: #ffffff;
  flex: 1;
  font-family: "Jost", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.button-default-pill.type-3-disabled {
  background-color: var(--background-color);
  opacity: 0.5;
}

.button-default-pill.type-3-hover, .button-default-pill:hover:not(.type-3-disabled) {
  cursor: pointer;
  background-color: var(--link-color) !important;
  .button-pill {
    background-color: var(--background-color) !important;
  }
}

.button-default-pill.type-3-active {
  background: linear-gradient(180deg, rgb(250, 38, 202) 0%, rgb(139.4, 85, 255) 100%);
  background-color: var(--background-color);
}

.button-default-pill.type-3-default {
  background-color: var(--background-color);
}

.button-default-pill.type-3-disabled .button-pill {
  background-color: var(--background-color);
}

.button-default-pill.type-3-active .button-pill {
  background-color: var(--dark-background);
}

.button-default-pill.type-3-default .button-pill {
  background-color: var(--background-color);
}
