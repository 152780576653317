.sidebar-sidebar {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 28px;
  padding: 0px 16px;
  position: relative;
  width: 230px;

  .menu-item {
    align-items: center;
    display: inline-flex;
    gap: 16px;
    height: 32px;
    position: relative;

    &-icon {
      height: 24px;
      position: relative;
      width: 24px;
    }

    &:hover:not(.disabled), &.active {
      cursor: pointer;

      .menu-item-text::after {
        position: absolute;
        display: block;
        height: 2px;
        width: 100%;
        content: ' ';
        bottom: -8px;
        background-image: linear-gradient(90deg, #46FF7A, #52CBE7, #7650DC);
      }
    }

    &-text {
      position: relative;
      color: white;
      font-family: Jost,serif;
      font-weight: 700;
      font-size: 18px;
    }
  }
}

