.servers-table {
  th > span {
    padding: 4px;
  }

  .btn {
    border-radius: 6px;
    border: none;
    margin: 4px;
    padding: 4px 8px;
    color: white;
    font-family: Jost;
    opacity: 0.8;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &-start {
      background: linear-gradient(0deg, #21D321 0%, #73F562 100%);
    }

    &-stop {
      background: linear-gradient(0deg, #FA273F 0%, #FA5970 100%);
    }

    &-terminate {
      background: linear-gradient(0deg, #0F1416 0%, #283632 100%);
    }
  }
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  width: 400px;
  padding: 30px;
  text-align: left;
  border-radius: 30px;
  color: #FFF;

  text-align: center;
  font-family: Jost;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: var(--Div-gradient, linear-gradient(124deg, #282E5C 10.37%, #020627 91.66%));
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  display: none;
  color: #FFF;

  text-align: center;
  font-family: Jost;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.react-confirm-alert-body > h3 {
  color: #FFF;

  text-align: center;
  font-family: Jost;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  border-radius: 10px;
  background: var(--dark-background, #020627);
  padding: 16px 18px;
  border: none;
  display: inline-block;
  color: #eee;
  margin-right: 10px;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    background: var(--gradient-coral, linear-gradient(60deg, #F094FA 13.4%, #F5576E 86.6%));
  }
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.text-center {
  color: white;
  text-align: center;
  width: 100%;
}
