.tarrifs-section {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.tarrifs-table {
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.row-instance {
  margin-bottom: unset !important;
  margin-top: unset !important;
}

.row-2 {
  margin-bottom: -12px !important;
  margin-top: -14px !important;
}

.row-3 {
  overflow: hidden !important;
}

.row-4 {
  color: transparent !important;
  margin-bottom: -30px !important;
  margin-top: -32px !important;
}

.icon-instance-node {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.row-5 {
  margin-bottom: -3px !important;
  margin-top: -5px !important;
}


