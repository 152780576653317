.products-menu {

  &-block {
    position: relative;
    cursor: pointer;
    z-index: 10000;
    width: 68px;

    &:hover {
      .products-menu-item {
        background: linear-gradient(135deg, #FA26CA 0%, #712AF9 100%);
      }
    }
  }

  &-item {
    transition: 0.5s;
    font-weight: 700;
    font-size: 16px;
    height: 58px;
    width: 58px;
    text-align: center;
    position: relative;
    z-index: 1;
    display: flex;
    background: linear-gradient(90.99deg, #46FF7A 0.27%, #52CBE7 30%, #7650DC 98.69%);
    border-radius: 6px;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row-reverse;

    &:hover {
      //box-shadow: 0px 0px 25px 1px #46FF7A;
    }
  }

  &-block:hover {
    .products-menu-content {
      display: flex;
      flex-direction: column;
      opacity: 1;
      visibility: visible;
    }
  }

  &-content {
    position: absolute;
    right: 0;
    min-width: 300px;
    opacity: 0;
    //display: none;
    visibility: hidden;
    transition: opacity 500ms;
    border-radius: 20px !important;
    padding: 20px;
    background: linear-gradient(123.97deg, #282E5C 10.37%, #020627 91.66%);
    box-shadow: 0px 4px 40px #000000;
    z-index: 15000;
  }

  &-link {
    color: white;
    background-color: transparent;
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 700;
    text-align: left;

    &-icon {
      img {
        height: 40px;
      }
    }

    &-container {
      &:hover {
        background: rgba(255, 255, 255, 0.09);
        border-radius: 10px;
        padding: 10px;
      }

      display: inline-flex;
      flex-wrap: nowrap;
      padding: 10px;
      align-items: center;
      column-gap: 10px;
      margin: 10px 0px 10px 0px;
    }
  }


}
