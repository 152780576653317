.radio-button {
  position: relative;
  cursor: pointer;
}

.radio-button .controller {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 24px;
  justify-content: center;
  position: relative;
  width: 24px;
}

.radio-button .thumb {
  border-radius: 24px;
  position: relative;
}

.radio-button .small-circle {
  align-self: stretch;
  background-color: var(--white);
  border-radius: 6px;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.radio-button.state-active {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
}

.radio-button.state-default {
  align-items: center;
  border-radius: 24px;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.radio-button.state-hover {
  align-items: center;
  background-color: #8b54ff26;
  border-radius: 24px;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.radio-button.state-active .thumb {
  align-items: center;
  align-self: stretch;
  background-color: #863ffe;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: center;
  padding: 6px;
}

.radio-button.state-default .thumb {
  border: 1px solid;
  border-color: #7650dc;
  height: 24px;
  width: 24px;
}

.radio-button.state-hover .thumb {
  border: 1px solid;
  border-color: #7650dc;
  height: 24px;
  width: 24px;
}
