.footer {
  align-items: flex-start;
  padding: 10px 0px;
  position: relative;

  a {
    color: #7780a1;
    font-family: Jost, Helvetica, serif;
    font-weight: 600;
  }
}

.footer .frame {
  align-items: center;
  display: flex;
  position: relative;
}

.footer .partners-chainback {
  color: #7780a1;
  font-family: "Jost", Helvetica;
  font-size: 18px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.footer .chainback-glyph {
  background: linear-gradient(180deg, rgb(70.12, 255, 121.89) 0%, rgb(82, 203, 231) 48.44%, rgb(118, 80, 220) 100%);
  border-radius: 10px;
  height: 20px;
  position: relative;
  width: 20px;
}

.footer .group {
  height: 11px;
  left: 3px;
  position: absolute;
  top: 4px;
  width: 13px;
}

.footer .text-wrapper-4 {
  color: #7780a1;
  font-family: "Jost", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.footer .privacy {
  color: #7780a1;
  font-family: "Jost", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.footer .frame-2 {
  align-items: center;
  flex: 0 0 auto;
  justify-content: center;
  position: relative;
}

.footer .text-wrapper-5 {
  color: #7780a1;
  font-family: "Jost", Helvetica;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.footer .privacy-2 {
  color: #7780a1;
  font-family: "Jost", Helvetica;
  font-size: 18px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.footer.desktop {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 24px;
  justify-content: space-between;
  width: 720px;
}

.footer.mobile {
  display: inline-flex;
  flex-direction: column;
  gap: 24px;
}

.footer.desktop .frame {
  gap: 8px;
  width: 357px;
}

.footer.mobile .frame {
  align-self: stretch;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.footer.desktop .partners-chainback {
  font-weight: 400;
}

.footer.mobile .partners-chainback {
  font-weight: 600;
}

.footer.desktop .frame-2 {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 24px 24px;
}

.footer.mobile .frame-2 {
  display: flex;
  gap: 8px;
  width: 357px;
}

.footer.desktop .privacy-2 {
  font-weight: 600;
}

.footer.mobile .privacy-2 {
  font-weight: 400;
}
