.launchpad-tab-panel {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 85px;
  justify-content: center;
  padding: 1px;
  position: relative;
  width: 226px;
  cursor: pointer;
}

.launchpad-tab-panel .tab {
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.launchpad-tab-panel .input-label-4 {
  color: #ffffff;
  flex: 1;
  font-family: "Jost", Helvetica;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.launchpad-tab-panel.type-1-hover, .launchpad-tab-panel:hover {
  background-color: #8b54ff !important;
  .tab {
    background-color: #13173f !important;
  }
}

.launchpad-tab-panel.type-1-active {
  background: linear-gradient(180deg, rgb(70.12, 255, 121.89) 0%, rgb(82, 203, 231) 48.44%, rgb(118, 80, 220) 100%);
  background-color: var(--background-color);
}

.launchpad-tab-panel.type-1-default {
  background-color: var(--background-color);
}

.launchpad-tab-panel.type-1-active .tab {
  background-color: var(--dark-background);
}

.launchpad-tab-panel.type-1-default .tab {
  background-color: var(--background-color);
}
