.amount-input {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
  width: 100%;
  cursor: pointer;

  &:hover:not(.disabled) .div-2 {
    //background-color: var(--variable-collection-background-color);
    background: linear-gradient(180deg, rgb(250, 38, 202) 0%, rgb(139.4, 85, 255) 100%);
    //border: 1px solid;
    border-color: var(--link-color);
    padding: 16px;
  }
}

.amount-input .div-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.amount-input .input-label-7 {
  color: #ffffff;
  flex: 1;
  font-family: "Jost", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  text-align: left;
  position: relative;

}

.amount-input .input-value-3 {
  background-color: transparent;
  border: 0;
  color: #ffffff;
  flex: 1;
  flex-grow: 1;
  font-family: "Jost", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  padding: 0;
  position: relative;
  text-align: right;
}

.amount-input.type-8-active {
  background: linear-gradient(180deg, rgb(250, 38, 202) 0%, rgb(139.4, 85, 255) 100%);
  border-color: var(--variable-collection-background-color);
  padding: 1px;
}

.amount-input.type-8-default {
  background-color: var(--variable-collection-background-color);
  border: 1px solid;
  border-color: var(--variable-collection-background-color);
  padding: 16px;
}

.amount-input.type-8-active .div-2 {
  background-color: var(--dark-background);
  border-radius: 10px;
  padding: 16px 18px;
}
