.application {
  text-align: center;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.blocked {
  pointer-events: none;
}

.text-bold {
  font-weight: bold;
}

body {
  font-family: Jost, serif;
  background-color: rgba(19, 23, 63, 1);
}

.virtual-machines {
  align-items: center;
  background-color: var(--variable-collection-background-color);
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  position: relative;
}

.virtual-machines .page-container {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: center;
  max-width: 1920px;
  position: relative;
  width: 100%;
}

.virtual-machines .left-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 50px;
  padding: 24px 15px;
  position: relative;
  width: 260px;
}

.virtual-machines .content-2 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--dark-bg);
  border-radius: 30px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  max-width: 1920px;
  padding: 24px;
  position: relative;
  width: 100%;
  min-height: 1000px;
}

.virtual-machines .chainback-logo {
  align-self: stretch !important;
  height: 50px !important;
  position: relative !important;
  width: 100% !important;
}

.plans-table {
  color: white;
  font-family: Jost;
  width: 100%;

  tbody, tr, td {
    //vertical-align: top;
  }

  th, td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.10);
    padding: 0px;
    margin: 0;
    &>span {
      display: flex;
      min-height: 100%;
      height: 70px;
      min-width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  th {
    font-weight: 500;
    font-family: "Inter", Helvetica;
  }

  td {
    font-family: Jost;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #DDD6ED;
    vertical-align: middle;
  }

  thead {

  }
}

