.btn-primary-md {
  align-items: center;
  border-radius: 6px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  padding: 18px 30px;
  position: relative;
  cursor: pointer;
}

.btn-primary-md .explorer {
  color: #ffffff;
  font-family: "Jost", Helvetica;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 134.73px;
}

.btn-primary-md.disabled {
  background: linear-gradient(135deg, #fa26ca, #8b55ff);
  opacity: 0.5;
}

.btn-primary-md:hover:not(.disabled) {
  background: linear-gradient( 90deg, #46ff7a, #52cbe7 48.44%, #7650dc);
}

.btn-primary-md.default {
  background: linear-gradient(135deg, #fa26ca, #8b55ff);
}
