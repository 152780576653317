.user-header-panel {
  position: relative;
  z-index: 999;

  .address-button {
    color: white;
    font-family: Jost;
    font-weight: 800;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 25px;
    border-radius: 10px;
    cursor: pointer;
    background: var(--variable-collection-background-color);

    &:hover {
      background: #020627;
    }

    &-container {
      padding: 1px;
      overflow: hidden;
      background: linear-gradient(90deg, #FA26CA 0%, #8B55FF 100%);
      border-radius: 10px;
    }
  }

  .user-info-block {
    position: absolute;
    right: 0;
    top: calc(100% + 20px);
    z-index: 999;

    display: flex;
    width: 370px;
    max-width: 370px;
    padding: 15px 25px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    border-radius: 30px;
    background: linear-gradient(124deg, #1C224E 10.37%, #03061B 91.66%);
    box-shadow: 0px 4px 40px 0px #000;
    overflow: hidden;

    &-body {
      text-align: left;
      color: white;
      width: 100%;

      h4 {
        color: #DDD6ED;
        font-family: Jost;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-top: 18px;
        margin-bottom: 10px;
      }

      .user-balance {
        color: #FFF;

        /* H3 */
        font-family: Jost;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 133.333% */

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        span {
          display: inline-block;
        }
      }

      .user-address {
        color: #FFF;

        font-family: Jost;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .user-logout-button {
        width: 100%;
        padding: 8px 0px;
        margin-top: 20px;
      }
    }
  }
}

.btn-top-up {

  &-body {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 8px 18px;
    border-radius: 10px;
    background-color: #020627;
    color: white;
    font-family: Jost, serif;
    font-size: 15px;
    font-weight: 700;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    cursor: pointer;
    .btn-top-up-body {
      background-color: #13173f
    }
  }

  padding: 1px;
  background: linear-gradient(90deg, #46FF7A 0%, #52CBE7 50%, #7650DC 100%);
  border: 1px;
  border-radius: 10px;
}

.top-up {
  &-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;

    .top-up-form-btn {
      border: none;
      cursor: pointer;

      &:hover:not(.disabled) {
        .top-up-form-btn-body {
          background: transparent;
        }
      }

      &-container {
        padding: 1px;
        border-radius: 10px;
        background: linear-gradient(90deg, #46FF7A 0%, #52CBE7 50%, #7650DC 100%);
      }

      &-body {
        border-radius: 10px;
        background: #020627;
        padding: 12px 18px;
        text-align: center;
        font-family: Jost, serif;
        font-weight: 800;
        font-size: 15px;
        color: white;
      }
    }
  }

  &-amount-choice {
    display: flex;
    flex-direction: row;
    gap: 8px;
    padding: 20px 0;

    &-button {
      &-container {
        background: linear-gradient(90deg, #EE0979 0%, #FF6A00 100%);
        border-radius: 10px;
        padding: 1px;
        flex: 1;
        cursor: pointer;

        &.selected, &:hover {
          .top-up-amount-choice-button-body {
            background-color: transparent;
          }
        }
      }


      &-body {
        border-radius: 10px;
        background: #020627;
        padding: 4px 18px;
        text-align: center;
        font-family: Jost, serif;
        font-weight: 800;
        font-size: 15px;
      }
    }

  }

  &-form {

    &-container {
      display: flex;
      flex-direction: row;
      gap: 24px;
      width: 100%;
    }

    &-field {
      display: flex;
      flex-direction: column;
      gap: 4px;

      & > span {
        color: #DDD6ED;
        font-size: 16px;
        font-family: Jost, serif;
        font-weight: 500;
      }
    }

    &-input {
      &-currency {

      }
    }

    &-input-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 12px;
      border: 1px solid rgba(#DDD6ED, 0.5);
      padding: 16px 18px;
      gap: 10px;
      width: 260px;

      input {
        &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        -moz-appearance: textfield;

        background-color: transparent;
        border: none;
        color: white;
        line-height: 24px;
        font-size: 16px;
        font-family: Jost, serif;
        display: inline-block;
        flex: 1;
        max-width: 120px;

        &:focus {
          outline: none;
        }
      }


    }
  }

  &-balance-container {
    display: flex;
    width: 100%;
    flex-direction: row;
  }

  &-chainback-logo {
    &-big {
      width: 90px;
      height: 90px;

    }

    &-small {
      width: 12px;
      height: 12px;
    }
  }

  &-balance-content {
    text-align: right;
    flex: 1;

    h3 {
      font-family: Jost, serif;
      font-weight: 700;
      line-height: 32px;
      font-size: 16px;
      color: #DDD6ED;
    }

    .top-up-balance-value {
      font-family: Jost, serif;
      font-weight: 700;
      line-height: 32px;
      font-size: 24px;
      color: white;
    }

    .top-up-balance-archive-value {
      font-family: Jost, serif;
      font-size: 18px;
      font-weight: 400;
      color: #DDD6ED;
    }

  }
}

.top-up-modal {
  background: linear-gradient(124deg, #1C224E 10.37%, #03061B 91.66%);
  box-shadow: 0px 4px 40px 0px #000;
  border-radius: 30px;
  padding: 40px;
  min-width: 600px;
  position: relative;
  color: white;
  outline: none;

  &-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &-overlay {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-close-btn {
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    opacity: 0.8;
    &:hover {
      opacity: 1;
    }
  }

  h2 {
    font-family: Jost, serif;
    font-weight: 700;
    font-size: 32px;
    text-align: center;
  }

  .message-after-top-up {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
  }
}
