.gpu-options {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.gpu-container {
  align-items: flex-start;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;

  .button-default-pill {
    max-width: 192px;
  }
}

.gpu-column {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  padding: 8px 4px;
  position: relative;
}
