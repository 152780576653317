.header {
  display: flex;
  gap: 24px;
  position: relative;
  width: 720px;
}

.header .div {
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 24px;
  position: relative;
}

.header .input-label {
  color: #ddd6ed;
  flex: 1;
  font-family: var(--paragraph-font-family);
  font-size: var(--paragraph-font-size);
  font-style: var(--paragraph-font-style);
  font-weight: var(--paragraph-font-weight);
  letter-spacing: var(--paragraph-letter-spacing);
  line-height: var(--paragraph-line-height);
  margin-top: -1px;
  position: relative;
  text-align: left;
}

.header .text-wrapper {
  color: #ddd6ed;
  font-family: var(--paragraph-font-family);
  font-size: var(--paragraph-font-size);
  font-style: var(--paragraph-font-style);
  font-weight: var(--paragraph-font-weight);
  letter-spacing: var(--paragraph-letter-spacing);
  line-height: var(--paragraph-line-height);
}

.header .btn-primary-md-instance {
  flex: 0 0 auto !important;
}

.header .header-responsive {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  position: relative;
}

.header .a-chainback-logo {
  align-self: stretch !important;
  position: relative !important;
  width: 230px !important;
}

.header .column-2 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  padding: 24px;
  position: relative;
}

.header .span-wrapper {
  color: #ddd6ed;
  flex: 1;
  font-family: "Jost", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.header .span {
  color: #ddd6ed;
  font-family: "Jost", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
}

.header .responsive-menu-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  justify-content: flex-end;
  padding: 24px;
  position: relative;
}

.header.property-1-default {
  align-items: center;
}

.header.property-1-variant-3 {
  align-items: flex-start;
}

.header.property-1-variant-2 {
  align-items: flex-start;
}

.header.property-1-default .div {
  align-items: flex-start;
  padding: 24px;
}

.header.property-1-variant-3 .div {
  align-items: center;
  justify-content: space-around;
}

.header.property-1-variant-2 .div {
  align-items: center;
}

.header.property-1-variant-3 .header-responsive {
  justify-content: space-between;
}

.header.property-1-variant-2 .header-responsive {
  gap: 24px;
}
