.form-field {
  align-items: flex-start;
  flex-direction: column;
  position: relative;
}

.form-field .input-label-6 {
  color: #ddd6ed;
  font-family: "Jost", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.form-field .input-value {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-radius: 12px;
  display: flex;
  height: 56px;
  justify-content: space-between;
  padding: 16px 18px;
  position: relative;
  width: 100%;
}

.form-field .label-wrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  position: relative;
}

.form-field .label {
  background-color: transparent;
  border: 0;
  color: #ddd6ed80;
  flex: 1;
  flex-grow: 1;
  font-family: "Jost", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.08px;
  line-height: 24px;
  margin-top: -1px;
  padding: 0;
  position: relative;
}

.form-field .input-value-controls {
  flex: 0 0 auto;
  position: relative;
}

.form-field .input-helper-text {
  color: var(--primarygray-60);
  font-family: "Jost", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.07px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.form-field .input-value-2 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-radius: 12px;
  display: flex;
  height: 56px;
  padding: 16px 18px;
  position: relative;
  width: 100%;
}

.form-field .label-2 {
  background: none;
  border: 0;
  color: #ddd6ed80;
  flex: 0 0 auto;
  flex-grow: 1;
  font-family: "Jost", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.08px;
  line-height: 24px;
  margin-top: -1px;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.form-field.input-hover {
  display: flex;
  gap: 10px;
  padding: 8px 0px;
  width: 120px;
}

.form-field.select-hover {
  display: flex;
  gap: 10px;
  padding: 8px 0px;
  width: 120px;
}

.form-field.select-hint-hover {
  display: inline-flex;
  gap: 4px;
  min-width: 120px;
}

.form-field.select-hint {
  display: inline-flex;
  gap: 4px;
  min-width: 120px;
}

.form-field.select {
  display: flex;
  gap: 10px;
  padding: 8px 0px;
  width: 120px;
}

.form-field.input {
  display: flex;
  gap: 10px;
  padding: 8px 0px;
  width: 120px;
}

.form-field.input-hover .input-value {
  border-color: #ddd6ed80;
}

.form-field.select-hover .input-value {
  border-color: #ddd6ed80;
}

.form-field.select-hint-hover .input-value {
  border-color: #8b54ff;
}

.form-field.select-hint .input-value {
  border-color: #ddd6ed80;
}

.form-field.select .input-value {
  border-color: #ddd6ed80;
}

.form-field.input .input-value {
  border-color: #ddd6ed80;
}

.form-field.input-hover .input-value-2 {
  border-color: #8b54ff;
  gap: 4px;
}

.form-field.select-hover .input-value-2 {
  border-color: #8b54ff;
  justify-content: space-between;
}

.form-field.select-hint-hover .input-value-2 {
  border-color: #ddd6ed80;
}

.form-field.select-hint .input-value-2 {
  border-color: #ddd6ed80;
}

.form-field.select .input-value-2 {
  border-color: #ddd6ed80;
  justify-content: space-between;
}

.form-field.input .input-value-2 {
  border-color: #ddd6ed80;
  gap: 4px;
}
