.os-card {
  align-items: center;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 149px;
  padding: 1px;
  position: relative;
  width: 106px;
}

.os-card .option-wrapper {
  align-items: center;
  align-self: stretch;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  position: relative;
  width: 100%;
}

.os-card .option-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  height: 147px;
  justify-content: space-between;
  padding: 16px 8px;
  position: relative;
  width: 100%;
}

.os-card .radio-block {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.os-card .class {
  flex: 0 0 auto !important;
}

.os-card .icon-park-twotone {
  height: 24px;
  position: relative;
  width: 24px;
}

.os-card .input-label-2 {
  align-self: stretch;
  color: #ddd6ed;
  font-family: "Jost", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.os-card .input-label-3 {
  align-self: stretch;
  color: #ddd6ed;
  font-family: "Jost", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
}

.os-card:hover {
  cursor: pointer;

  background-color: var(--additional-color) !important;
}

.os-card.type-active {
  background: linear-gradient(180deg, rgb(70.12, 255, 121.89) 0%, rgb(82, 203, 231) 48.44%, rgb(118, 80, 220) 100%);
  background-color: var(--background-color);
}

.os-card.type-default {
  background-color: var(--background-color);
}

.os-card.type-active .option-wrapper {
  background-color: var(--dark-background);
}

.os-card.type-default .option-wrapper {
  background-color: var(--background-color);
}
