.virtual-machines .configuration-block {
  align-items: center;
  align-self: stretch;
  background-color: #13173f80;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  padding: 16px;
  position: relative;

  h4 {
    color: #ddd6ed;
    font-family: "Jost", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: normal;
    text-align: left;
    width: 100%;
  }
}

.virtual-machines .configuration-block-2 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  margin-top: -2px;
  position: relative;
  text-align: center;
}

.virtual-machines .form-field-instance {
  align-self: stretch !important;
  display: flex !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.virtual-machines .configuration-block-3 {
  gap: 4px !important;
  justify-content: unset !important;
}

.virtual-machines .configuration-block-4 {
  flex: unset !important;
  flex-grow: unset !important;
  width: 92.5px !important;
}

.virtual-machines .configuration-block-5 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--h-2-font-family);
  font-size: var(--h-2-font-size);
  font-style: var(--h-2-font-style);
  font-weight: var(--h-2-font-weight);
  letter-spacing: var(--h-2-letter-spacing);
  line-height: var(--h-2-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.virtual-machines .configuration-block-6 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;

    &:active {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      border-radius:12px;
      background-color: transparent;
    }
  }

  .button-container {
    text-align: left;
    width: 100%;
  }
}

.virtual-machines .form-field-2 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  min-width: 120px !important;
  width: 100% !important;
}

.virtual-machines .form-field-3 {
  margin-left: 0px !important;
}

.virtual-machines .form-field-4 {
  gap: 4px !important;
  justify-content: flex-end !important;
}

.virtual-machines .configuration-block-7 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
  padding: 8px 4px;
  position: relative;
  width: 100%;
}

.virtual-machines .amount-input-instance {
  color: #ddd6ed !important;
}

.virtual-machines .amount-input-2 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  margin-bottom: -7px !important;
  width: 100% !important;
}

.text-input {
  width: 100%;
  color: #DDD6ED;
  font-family: "Jost", Helvetica;
  line-height: 24px;
  font-size: 16px;
  padding: 16px 18px;
  background: none;
  border:1px solid rgba(221, 214, 237, 0.5);
  border-radius: 12px;

  &:hover {
    border:1px solid rgba(221, 214, 237, 0.8);
  }

  &:active, &:focus {
    border:1px solid #8b54ff;
  }

  &:visited {
    color: #DDD6ED;
    border: 1px solid rgba(221, 214, 237, 0.5);
  }

  &::placeholder, &::-ms-input-placeholder, &::-webkit-input-placeholder {
    color: rgba(221, 214, 237, 0.5);
  }

  &:focus {
    outline: 0;
  }
}

