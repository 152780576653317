.responsive-menu {
  height: 40px;
  position: relative;
  width: 40px;
}

.responsive-menu .line {
  height: 3px;
  left: 6px;
  position: absolute;
  top: 13px;
  width: 28px;
}

.responsive-menu .img {
  height: 3px;
  left: 6px;
  position: absolute;
  top: 19px;
  width: 28px;
}

.responsive-menu .line-2 {
  height: 3px;
  left: 14px;
  position: absolute;
  top: 25px;
  width: 20px;
}

.responsive-menu .property {
  height: 40px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 40px !important;
}
