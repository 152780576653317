.property-default-wrapper {
  align-items: flex-start;
  display: flex;
  gap: 24px;
  position: relative;
  width: 960px;
}

.property-default-wrapper .column-5 {
  align-items: flex-start;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-color: #ffffff1a;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 24px;
  justify-content: center;
  padding: 8px 24px;
  position: relative;
}

.property-default-wrapper .additional-disk-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  height: 48px;
  justify-content: center;
  max-width: 250px;
  padding: 8px 0px;
  position: relative;
}

.property-default-wrapper .additional-disk {
  color: #ddd6ed;
  font-family: var(--font-medium-font-family);
  font-size: var(--font-medium-font-size);
  font-style: var(--font-medium-font-style);
  font-weight: var(--font-medium-font-weight);
  letter-spacing: var(--font-medium-letter-spacing);
  line-height: var(--font-medium-line-height);
  position: relative;
  width: fit-content;
}

.property-default-wrapper .element-GB-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 24px;
  height: 48px;
  justify-content: center;
  padding: 8px 0px;
  position: relative;
}

.property-default-wrapper .element-GB {
  color: #ddd6ed;
  font-family: var(--paragraph-font-family);
  font-size: var(--paragraph-font-size);
  font-style: var(--paragraph-font-style);
  font-weight: var(--paragraph-font-weight);
  letter-spacing: var(--paragraph-letter-spacing);
  line-height: var(--paragraph-line-height);
  position: relative;
  text-align: center;
  width: fit-content;
}

.property-default-wrapper:hover {
  background-color: #ffffff03;
}
